<template>
  <div class="th-list-course">
    <h2 class="th-title">Course</h2>
    <a-card>
      <div class="d-flex justify-content-between mb-3">
        <a-input
          style="width: 200px"
          placeholder="Cari Judul Course..."
          v-model:value="filter.name"
          @change="fetchCourse"
        >
          <template #prefix>
            <span>
              <search-outlined style="color: rgba(0, 0, 0, 0.25)" />
            </span>
          </template>
        </a-input>
        <a-button @click.prevent="showModalAdd">
          <template #icon><plus-outlined /></template>
          Buat Course Baru
        </a-button>
        <a-modal v-model:visible="modalAddVisible" title="Tambahkan Course" @ok="handleOk">
          <a-form :model="courseGeneralForm" layout="vertical">
            <a-form-item required label="Judul Course" v-bind="validateInfos.name">
              <a-input v-model:value="courseGeneralForm.name" />
            </a-form-item>
            <a-form-item label="Deskripsi">
              <a-textarea :rows="6" v-model:value="courseGeneralForm.description"></a-textarea>
            </a-form-item>
            <a-row :gutter="[32,0]">
              <a-col :md="24" :lg="12">
                <a-form-item required label="Vendor/Instruktur" v-bind="validateInfos.vendorId">
                  <a-select v-model:value="courseGeneralForm.vendorId">
                    <a-select-option v-for="vendor in vendors" :key="vendor._id" :value="vendor._id">{{vendor.name}}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="24" :lg="12">
                <a-form-item required label="Kategori" v-bind="validateInfos.categoryId">
                  <a-select v-model:value="courseGeneralForm.categoryId">
                    <a-select-option v-for="category in categories" :key="category._id" :value="category._id">{{category.name}}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="24" :lg="12">
                <a-form-item required label="Tipe Course">
                  <a-select v-model:value="courseGeneralForm.type">
                    <a-select-option value="free">Gratis</a-select-option>
                    <a-select-option value="premium">Berbayar</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="24" :lg="12" v-if="courseGeneralForm.type === 'premium'">
                <a-form-item required label="Harga" v-bind="validateInfos.price">
                  <div class="d-flex align-items-center">
                    <a-input-number
                      class="w-100 ml-2 mr-1"
                      :min="1"
                      v-model:value="courseGeneralForm.price"
                      :formatter="value => rupiahPrice(value)"
                      :parser="value => value.replace(/Rp\s|\.|\,00/g, '')"
                    />
                  </div>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
          <template #footer>
            <a-button @click.prevent="() => modalAddVisible = false" key="cancel">Batalkan</a-button>
            <a-button @click.prevent="confirmAdd" key="submit" type="primary" html-type="submit">Tambahkan</a-button>
          </template>
        </a-modal>
      </div>
      <div>
        <a-table :columns="columns" :data-source="courseData" bordered>
          <template #name="{ text, record }">
            <router-link class="th-link" :to="{ name: 'Detail Course', params: { id: record.key }}">
              {{text}}
            </router-link>
          </template>
          <template #description="{ text }">
            <span>
              {{text || '-'}}
            </span>
          </template>
          <template #status="{ text }">
            <a-tag :color="text === 'published' ? 'green' : 'orange'">
              {{ text === 'published' ? 'TERPUBLIKASI' : 'DRAF' }}
            </a-tag>
          </template>
          <template #price="{ record }">
            <span v-if="record.price == 0 || record.type === 'free'">Gratis</span>
            <span v-else>{{rupiahPrice(record.price)}}</span>
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { onMounted, ref, reactive, createVNode, toRef, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';
import { SearchOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
import rupiahPrice from '@/helpers/rupiahPrice'
import { Modal, notification } from 'ant-design-vue';
import { useForm } from '@ant-design-vue/use';

const columns = [
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
    slots: { customRender: 'name' },
  },
  {
    title: 'Deskripsi',
    dataIndex: 'description',
    key: 'description',
    slots: { customRender: 'description' },
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor',
    key: 'vendor',
    slots: { customRender: 'description' },
    align: 'center',
  },
  {
    title: 'Harga',
    dataIndex: 'price',
    key: 'price',
    slots: { customRender: 'price' },
    align: 'center',
  },
  {
    title: 'Status',
    dataIndex: 'statusPublication',
    key: 'status',
    slots: { customRender: 'status' },
    align: 'center',
  },
];

export default {
  components: { PlusOutlined, SearchOutlined },
  setup() {
    let store = useStore()
    let courseData = ref([])
    let sortRef = ref(null)
    let filter = reactive({
      name: null,
    })
    let pageRef = ref(null)
    let modalAddVisible = ref(false)
    const loadingAdd = ref(false)
    const router = useRouter()
    onMounted(async () => {
      fetchCourse()
      fetchVendors()
      fetchCategories()
    })

    const rulesRef = reactive({
      name: [
        {
          required: true,
          message: 'Nama harus diisi!',
          trigger: 'change',
        },
      ],
      categoryId: [
        {
          required: true,
          message: 'Kategori harus diisi!',
          trigger: 'change',
        },
      ],
      vendorId: [
        {
          required: true,
          message: 'Vendor harus diisi!',
          trigger: 'change',
        },
      ],
      // price: [
      //   {
      //     required: true,
      //     type: 'number',
      //     message: 'Apabila berbayar, harga harus diisi!',
      //     trigger: 'change',
      //   },
      // ],
    })

    let statusPublication = ref('all')
    let vendors = ref([])
    let categories = ref([])
    let courseGeneralForm = reactive({
      name: null,
      description: null,
      price: 1,
      type: 'free',
      vendorId: null,
      categoryId: null,
    })

    const fetchVendors = async () => {
      const rawData = await store.dispatch('vendor/FETCH_VENDOR')
      vendors.value = rawData.map(vendor => {
        return {
          name: vendor.firstName + (vendor.lastName ? ' ' + vendor.lastName : ''),
          ...vendor,
        }
      })
    }

    const fetchCategories = async () => {
      categories.value = await store.dispatch('category/FETCH_CATEGORY')
    }

    const fetchCourse = async () => {
      const { value:sort } = sortRef
      const { value: page } = pageRef
      const { value:status } = statusPublication
      const name = filter.name
      const rawData = await store.dispatch('course/FETCH_ALL_COURSE', {
        sort, name, page, statusPublication: status,
      })
      // console.log('fetchCourse rawData => ', rawData)
      courseData.value = rawData.map(course => {
        return {
          key: course._id,
          ...course,
          vendor: course.vendor && '_id' in course.vendor ? course.vendor.firstName + (course.vendor.lastName ? (' ' + course.vendor.lastName) : '') : null,
        }
      })
    }

    const goTo = (name, params) => {
      router.push({ name, params })
    }

    const showModalAdd = () => {
      if (vendors.value.length && categories.value.length) {
        modalAddVisible.value = true
      } else {
        return notification.error({
          message: `Harap membuat kategori dan vendor terlebih dahulu.`,
        })
      }
    }

    const handleOk = () => {
      modalAddVisible.value = false
    }

    const { resetFields, validate, validateInfos } = useForm(courseGeneralForm, rulesRef);

    const confirmAdd = async () => {
      try {
        await validate()
        if (courseGeneralForm.type === 'premium' && !courseGeneralForm.price) {
          return notification.error({
            message: `Harga harus diisi apabila tipe course berbayar.`,
          })
        }
        Modal.confirm({
          title: 'Apa anda yakin ingin menambahkan course?',
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const data = await store.dispatch('course/ADD_COURSE', { ...courseGeneralForm })
              modalAddVisible.value = false
              fetchCourse()
              notification.success({
                message: `Berhasil Menambahkan '${courseGeneralForm.name}' Dari Daftar Course`,
              })
              resetFields()
            } catch (err) {
              console.log(err)
              console.log(courseGeneralForm)
            }
          },
          onCancel() {},
        })
      } catch(err) {
        console.log(err)
        console.log(courseGeneralForm)
      }
    }

    return {
      courseData,
      sortRef,
      filter,
      pageRef,
      columns,
      goTo,
      rupiahPrice,
      categories,
      vendors,
      courseGeneralForm,
      rulesRef,
      showModalAdd,
      handleOk,
      modalAddVisible,
      loadingAdd,
      confirmAdd,
      validateInfos,
      statusPublication,
      fetchCourse,
    }
  },
}
</script>
<style lang="scss">
.th-list-course {
  .th-link {
    color: var(--vb-color-primary)!important;
    &:hover {
      color: var(--kit-color-primary)!important;
    }
  }
}
</style>
